<template>
    <div :class="$style.box">
      <div class="m-t-10 flex tl ">
      	<div class="bg-f w-390">
      		<div class="bg-f p-0-20 title border-b h-50 l-h-50 flex">
      			<p class="blod f-16">
      				<i class="shu top-2 relative m-r-5"></i> 行业新闻
      			</p>
      			<p class="c-9 cursor flex-r " @click="Morenews('HYXW')">查看更多  <img class="" src="@/assets/img/dow.png" alt=""></p>
      		</div>
      		<div class="p-10-0">
      			<ol class="p-10-20 l-h-26">
      				<li v-for="(item,index) in $store.state.app.newslist.HYXW" :key="index" :class="{'l-s-n disc-n':index==0}" class="cursor ell" @click="articledetail(item.id,'HYXW')">
      					<div class="m-b-10  flex " v-if="index==0">
      						<div ><img  class="w-150 h-100 rad-4 m-r-10" :src="$store.state.app.newslist.HYXW[0].imageurl||require('@/assets/img/noimg1.png')" alt=""></div>
      						<p> {{item.title}}</p>
      					</div>
      					<span v-else><span class="f-10">● </span> {{item.title}} </span>
      				</li>
      			</ol>
      		</div>
      	</div>
      	<div class="bg-f w-390">
      		<div class="bg-f p-0-20 title border-b h-50 l-h-50 flex">
      			<p class="blod f-16"><i class="shu top-2 relative m-r-5"></i> 知识分享</p>
      			<p class="c-9 cursor flex-r " @click="Morenews('ZSFX')">查看更多 <img class="" src="@/assets/img/dow.png" alt=""/></p>
      		</div>
      		<ol class="p-10-20 l-h-26">
      			<li v-for="(item,index) in $store.state.app.newslist.ZSFX" class="cursor ell" :key="index"
      				@click="articledetail(item.id,'ZSFX')">
      				<span class="f-10">● </span>{{item.title}}</li>
      		</ol>
      	</div>
      	<div class="bg-f w-390">
      		<div class="bg-f p-0-20 title border-b h-50 l-h-50 flex">
      			<p class="blod f-16">
      				<i class="shu top-2 relative m-r-5"></i> 法律法规
      			</p>
      			<p class="c-9 cursor flex-r" @click="Morenews('FLFG')">查看更多<img class=" m-l-5" src="@/assets/img/dow.png" alt=""/></p>
      		</div>
      		<ol class="p-10-20 l-h-26">
      			<li v-for="(item,index) in $store.state.app.newslist.FLFG" class="cursor ell" :key="index" @click="articledetail(item.id,'FLFG')"> <span class="f-10">● </span> {{item.title}}</li>
      		</ol>
      	</div>
      		
      </div>
    </div>
</template>

<script>
export default {
    name: 'newslist',
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('formData',e)
		}
	},
	created() {
		this.getnewslist()
	},
	methods: {
		
		
		// 文章更多
		Morenews: function Morenews(item) {
			console.log(item);
			if(location.href.indexOf('edit')==-1){
				document.documentElement.scrollTop=0
				this.$router.push({path:'/newslist',query:{type:item,showdetail:false}})
			}
			
		},
		// 文章详情
		articledetail: function articledetail(id, type) {
			if(location.href.indexOf('edit')==-1){
				document.documentElement.scrollTop=0
				this.$router.push({path:'/newslist',query:{type:type,showdetail:true,detailid:id}})
			}
		
		},
	},
};
</script>
<style>
	h2 {
	    text-align: center;
	    font-size: 38px;
	    padding: 30px 10px;
	    font-weight: bold;
	}
</style>
<style module>
   .box{margin-bottom:10px}
</style>
